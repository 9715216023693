@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .logotigocss {
    margin-top: 0%;
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1920px) {
  .logotigocss {
    margin-top: 8%;
  }
}
