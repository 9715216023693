.App {
  text-align: center;
}

.Cursor {
  cursor: pointer;
}

.Drawer {
  margin-left: 74px;
  padding: 3%;
}

.Fontdrawer {
  font-size: 16px;
  color: #343c46;
  font-weight: bold;
}

.swal-button-contained {
  width: 118px;
  height: 40px;
  background: #ffc10e !important;
  color: #343c46 !important;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.swal-button-outlined {
  width: 118px;
  height: 40px;
  background: #ffffff !important;
  color: #343c46 !important;
  border: 1px solid #ffc10e !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.swal-title {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #404a56 !important;
  text-align: start !important;
  margin: 0 8px !important;
}

.swal-htmlContainer {
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px !important;
  color: #404a56 !important;
  text-align: start !important;
}

[contenteditable] {
  outline: 0px solid transparent;
  border: 1px solid #bfc7d0;
  border-radius: 8px;
  width: 100%;
  min-height: 100px;
  padding: 5px;
}

[contenteditable]:hover {
  border: 1px solid #000;
}

[contenteditable]:focus {
  border: 2px solid #0134be;
}

.content-image {
  width: 350px;
  display: block;
  margin: auto;
  border: 2px solid #dfe3e7;
  border-radius: 8px;
}

/* .content-image:hover {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  transform: scale(1.5);
  transform-origin: bottom;
} */

.mui-card {
  border-radius: 16px !important;
  box-shadow: 0 4px 11px rgba(194, 209, 217, 0.46) !important;
}

.formulario > div {
  margin-bottom: 20px;
}

.formulario label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  color: #00377c;
}

.formulario .error {
  color: #b00020;
  font-size: 12px;
  margin-left: 16px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.head-login-container {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 200px;
  background: linear-gradient(
    4.38deg,
    #fabe00 5.73%,
    #fabe00 52.18%,
    #fbcc34 93.05%
  );
  border-radius: 0 0 30px 30px;
}
.MuiCircularProgress-svg {
  color: #fabe00;
}
.head-login-container img {
  width: 224px;
}

.body-login-container {
  position: sticky;
  z-index: 0;
  margin-top: 155px !important;
  min-height: 560px;
  border-radius: 16px;
  box-shadow: 0px 4px 11px rgba(194, 209, 217, 0.46);
  background: #fff;
  padding: 0px 50px;
}
/* TODO VERIFICA ESTO */
.body-login-container .footer {
  margin-top: 30px;
  margin-bottom: 40px;
}

.body-login-container .text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #4c5866;
}

.footer-login-container {
  width: 98%;
  height: 72px;
  background: #f8f9fb;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

.footer-login-container .text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #4c5866;
}

.formulario .title {
  display: flex;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #404a56;
  padding: 50px 0;
}

.authentication-button {
  width: 100%;
  background-color: #fabe00 !important;
}

.text-field-help {
  height: 732px;
  min-width: 40px;
}

.help-center-container {
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

.help-center-form {
  width: 780;
  align-items: "center";
}

.category-card {
  height: 164;
  border-radius: "16px !important";
}

.css-1gs1y0x-MuiTablePagination-selectLabel,
.css-14ucidt-MuiTablePagination-displayedRows {
  margin-bottom: 0px;
}
